export function authHeader() {
    // return authorization header with jwt token
    let token = JSON.parse(localStorage.getItem('client_token'));

    if(token) {
        return { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json' };
    } else {
        return {};
    }
}
