import React, { Suspense } from "react";

import Loader from './components/Loader';

const Admin = React.lazy(() => import('./Pages/Admin'));
const PageLayout = React.lazy(() => import('./layout'));

const ModuleLoader = (props) => {
    console.log(props.match.params);
    let bodyPage;
    switch(props.match.params.module) {
        case 'admin':
            bodyPage = <Admin />;
            break;
        default:
            bodyPage = <PageLayout {...props.match.params} />;
            break;
    }

    return (
        <Suspense fallback ={<Loader />}>
            {bodyPage}
        </Suspense>
    )
};

export default ModuleLoader;
