import React, { createContext, useState, useEffect } from "react";

import * as NewsService from "../_services/news_service";
import * as ContentService from "../_services/content_service";

export const MainContext = createContext({});

export const MainContextProvider = ({ children }) => {
    const [state, getState] = useState({
        videos: [],
        cats: [],
        types: [],
        contents: [],
        token: null,
    });

    useEffect(() => {
        let response = {};
        NewsService.getTypes().then((cats) => {
            response.cats = cats;
            NewsService.getToken().then((result) => {
                response.token = result.token;
                ContentService.getContents().then((result) => {
                    response.types = result;
                    ContentService.getVideos().then((result) => {
                        response.videos = result;
                        getState(response);
                    });
                    //getState(response);
                });
            });
        });
    }, []);
    console.log(state);
    return (
        <MainContext.Provider value={{ state }}>
            {children}
        </MainContext.Provider>
    );
};
