import { authHeader } from '../_utils';

const apiUrl = process.env.REACT_APP_API_URL;

const getOptions = {
    method: 'GET',
    headers: authHeader()
};

const postOptions = {
    method: 'POST',
    headers: authHeader()
};

export const getVideos = async () => {
    const response = await fetch(apiUrl + "/api/contents/videos", getOptions);
    const data = await response.json();
    console.log(response);
    return data;
};

export const getContents = async () => {
    const response = await fetch(apiUrl + "/api/contents/types", getOptions);
    const data = await response.json();
    return data;
};

export const setContent = (datas,nexts) => {
    postOptions.body = JSON.stringify(datas);
    console.log('setContent',JSON.stringify(datas));
    fetch(apiUrl + "/api/admin/contents", postOptions)
   .then(response => response.json())
   .then(result => getContents()
      .then(result => {
          nexts(result);
      })
   );
};

export const deleteContent = (datas,nexts) => { //ene removeVideo
    postOptions.body = JSON.stringify({ id:datas });
    console.log('setContent',JSON.stringify(datas));
    fetch(apiUrl + "/api/admin/videos/delete", postOptions)
   .then(response => response.json())
   .then(result => getVideos()
      .then(result => {
          nexts(result);
      })
    );
};

export const deleteContents = (datas,nexts) => {
    postOptions.body = JSON.stringify({ id:datas });
    console.log('setContent',JSON.stringify(datas));
    fetch(apiUrl + "/api/admin/contents/delete", postOptions)
   .then(response => response.json())
   .then(result => getContents()
      .then(result => {
          nexts(result);
      })
    );
};


export const setVideo = (datas,nexts) => {
    postOptions.body = JSON.stringify(datas);
    fetch(apiUrl + "/api/admin/videos", postOptions)
   .then(response => response.json())
   .then(result => getVideos()
      .then(result => {
          nexts(result);
      })
    );
};
