import React, { createContext, useReducer } from 'react';

import authReducer from './reducer';

export const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
    const initialState = {
        token: JSON.parse(localStorage.getItem('client_token') || null),
        isAuth: JSON.parse(localStorage.getItem('client_token')) ? true : false
    };

    console.log(initialState);

    const [state, dispatch] = useReducer(authReducer, initialState);

    return (
        <AuthContext.Provider value ={{state, dispatch}}>
            {children}
        </AuthContext.Provider>
    );
}
