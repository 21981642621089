import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import 'bulma/css/bulma.css'
import './App.css'

import { MainContextProvider } from './_store/main.store.js';
import { AuthContextProvider } from './_store/auth.store.js';
import ModuleLoader from './moduleLoader';

function App() {
    return (
        <div className ="App">
            <div className ="main_container">
                <BrowserRouter>
                    <MainContextProvider>
                        <AuthContextProvider>
                            <Route path="/:module?/:id?/:token?" component={ModuleLoader} />
                        </AuthContextProvider>
                    </MainContextProvider>
                </BrowserRouter>
            </div>
        </div>
    );
}

export default App;
