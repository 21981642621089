import { authHeader } from '../_utils';

const apiUrl = process.env.REACT_APP_API_URL;

const getOptions = {
    method: 'GET',
    headers: authHeader()
};

const postOptions = {
    method: 'POST',
    headers: authHeader()
};

export const getNews = async (params) => {
    const response = await fetch(apiUrl + "/api/news" + (params === undefined ? '' : params), getOptions);
    const result = await response.json();
    return result;
};

export const getToken = async () => {
    const response = await fetch(apiUrl + "/api/news/token", getOptions);
    const result = await response.json();
    return result;
};

export const getTypes = async (next) => {
    const response = await fetch(apiUrl + "/api/news/types", getOptions);
    const result = await response.json();
    return result;
};

export const getCovers = async (next) => {
    const response = await fetch(apiUrl + "/api/cover", getOptions);
    const result = await response.json();
    return result;
};

export const setNews = (datas) => {
    postOptions.body = JSON.stringify(datas);
    fetch(apiUrl + "/api/admin/news", postOptions)
   .then(response => response.json())
   .then(result => console.log(result));
};
